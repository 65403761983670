import React, { useEffect, useState } from "react";
import { TabularColumn, PageHeading, DatePicker, Bound, Icon, Tabular, Button, Loading } from "@opidcore/components";
import moment from "moment";
import _ from "lodash";
import FullTextSearchable from "../../components/FullTextSearchable";
import { Link } from "react-router-dom";
import { StaffBadge } from "../UIUtils";
import PriceIncreaseFile from "./PriceIncreaseFile";
import PriceIncreaseImporter from "./PriceIncreaseImporter";

function postForm(path, params, method) {
  method = method || 'post';

  var form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);
  for (var key in params) {
      if (params.hasOwnProperty(key)) {
          var hiddenField = document.createElement('input');
          hiddenField.setAttribute('type', 'hidden');
          hiddenField.setAttribute('name', key);
          hiddenField.setAttribute('value', params[key]);

          form.appendChild(hiddenField);
      }
  }
  document.body.appendChild(form);
  form.submit();
}

const PriceIncreaseLanding = () => {
  APP.setState("currentMenu", ["tools", "price_increase_landing_menu_key"]);

  const [priceIncreaseFiles, setPriceIncreaseFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchPriceIncreaseFiles, setFetchPriceIncreaseFiles] = useState(false);
  const [dateRangeState, setDateRangeState] = useState({ fromDate: moment().subtract(30, "days").format("YYYY-MM-DD"), toDate: moment().format("YYYY-MM-DD") });

  const dateUpdated = () => {
    setFetchPriceIncreaseFiles(true); 
  };

  const downloadPITemplate = () => { 
    //NOTE: WE HAVE TO USE THE FOLLOWING LOGIC TO INCLUDE JWT TOKEN AND USER AS PART OF REQUEST FOR SECURITY VALIDATION.   
    setLoading(true);    
    let loggedInUser = sessionStorage.getItem("loggedInUser")? sessionStorage.getItem("loggedInUser"): localStorage.getItem("loggedInUser");
		let jwtToken = localStorage.getItem("jwtToken");
    let url = "/api/v1/reports/exportPriceIncrease"
    postForm(url, {uin: loggedInUser, bin: jwtToken});  
    setTimeout(()=>setLoading(false), 25000 );   
    //window.open("/api/v1/reports/exportPriceIncrease");
  }

  const beginPIProcess = () => {
    APP.instance.createModal(<PriceIncreaseImporter />, {modal_name: "PI Importer"});
  }

  const downloadFile = (storageIdentifier, filename) => {
    window.open("/file/download/" + storageIdentifier + "/" + filename);
  }

  const openPIRowsModal = (row, type) => {
    APP.instance.createModal(<PriceIncreaseFile fileId={row.attachment} filename={row.filename} type={type} viewOnly={true}/>, {modal_name: row.filename + " Rows"});
  } 

  return (
    <div className="price-increase-landing">
      <PageHeading size="h3">Price Increase Uploads</PageHeading>

      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: "1" }}>
          <FullTextSearchable
            model={"ContractLineIncrease"}
            method={"reasonablePriceIncreaseFileList"}
            setData={setPriceIncreaseFiles}
            doFetchData={fetchPriceIncreaseFiles}
            setDoFetchData={setFetchPriceIncreaseFiles}
            maxRows={-1}
            allowBlank={true}
            additionalParams={[dateRangeState.fromDate, dateRangeState.toDate]}
            filterOptions={{
              columns: [
                { column: "uploaded_by_name", heading: "Uploaded By" },
              ],
            }}
          />

          <Bound to={dateRangeState} onChange={() => dateUpdated()}>
            <div style={{ display: "flex" }}>
              <DatePicker name="Start" field="fromDate" />
              <DatePicker name="End" field="toDate" />
            </div>
          </Bound>
        </div>

        <div className="price-increase-buttons">
          {loading? <Loading/> : <Button onClick={downloadPITemplate}>Download PI Template</Button>}
          <Button style={{backgroundColor: "#1CB34B"}} onClick={beginPIProcess}>Begin PI Process</Button>
        </div>
      </div>

      <Tabular data={priceIncreaseFiles} onClick={null} pageSize={10} paginationLocation={"both"}>
        <TabularColumn title="Filename" data={(row) => <Link to="#" onClick={() => downloadFile(row.storage_identifier, row.filename)}>{row.filename}</Link>} />
        <TabularColumn title="Uploaded By" data={(row) => <StaffBadge staff={row.uploaded_by_obj} />} />
        <TabularColumn title="Date Uploaded" data={(row) => row.date_uploaded} />
        <TabularColumn title="Total Rows in File" data={(row) => <Link to="#" onClick={() => openPIRowsModal(row, "all")}>{row.total_rows}</Link> } />
        <TabularColumn title="Uploaded Rows" data={(row) => <Link to="#" onClick={() => openPIRowsModal(row, "applied")}>{row.uploaded_rows}</Link>} />
        <TabularColumn title="Failed Rows" data={(row) => <Link to="#" onClick={() => openPIRowsModal(row, "failed")}>{row.failed_rows}</Link>} />
        <TabularColumn title="Status" data={(row) => row.status} />
        <TabularColumn 
          title="Edit"
          data={(row) => (
            <Link onClick={(e) => e.stopPropagation()} to={"/ui/priceincrease/file/" + row.attachment}>
              <Icon icon="edit" size="1x" />
            </Link>
          )}
        />
      </Tabular>
    </div>
  );
};

export default PriceIncreaseLanding;
