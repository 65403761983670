import React, { useContext, useEffect, useRef, useState } from 'react';
import { Bound, Button, InputAutoComplete, Workflow, Step, PageHeading, InputSelect, Icon } from '@opidcore/components';
import { GroupBreakdown, Group } from '.';
import _ from 'lodash';
import { DataContext } from '@opidcore/components/Bound';
import { NiceAddress } from './NiceBox';
//import Util from '@opidcore/Util';
import { CurrentModal } from '@opidcore/components/OpidApplication';
import ClientEdit from '../UI/Customer/EditClient';
import SiteEdit from '../UI/Site/EditSite';
import EditService from '../UI/Service/EditService';
//import { Bounds } from 'leaflet';

const checkIsChecked = (groupData,currentSelection)=>{
    return  _.difference(_.map(groupData,'key'), currentSelection).length == 0;
}

const checkSite = (sites,boundMagic,e) => {
    const currentSites = boundMagic.magicalGet("sites", []);
    const isSingle = boundMagic.magicalGet("single", false);
    let newSites = [];
    if (e.currentTarget.checked == true){
        newSites = _.unionBy( isSingle ? [] : currentSites, sites, 'key');
    }else{
        newSites = _.filter(currentSites, (s)=>_.map(sites,'key').indexOf( s.key ) == -1 );
    }
    boundMagic.magicalSet("sites", newSites);
};

const TitleLabel = ({label = "", name = undefined})=>{
    return <><strong>{label}</strong>{name != undefined && name.length > 0? " - " + name : ""}</>;
}

const ServiceData = ({data = undefined, label = "", serviceCheckAction}) => {
    if(data == undefined){
        return <span key="no_service">No Service</span>
    }

    const level = 2;

    const checkService = (service) => {
        serviceCheckAction(service);
    }


    const viewService = (service) => {
        APP.instance.createModal("/ui/services/edit/" + service.friendly_id, { modal_name: "Service #" + service.friendly_id }, {});
    }
    
    
    return <div key={"service_" + data.id}  style={{ marginLeft: 3 * level + "em", marginBottom: "3px" }}>
        <div>
            {serviceCheckAction != undefined? <span className="check-service"><input type="checkbox" onChange={()=>checkService(data)} /></span> : null}
            <span className="title">
                <TitleLabel label={label} name={(data.description || "No Description") + (data.container_name ? " - " + data.container_name : "") + (data.schedule_days ? " - " + data.schedule_days : "")   }/>
                <Icon icon="search" onClick={()=>viewService(data)}/>
            </span>
        </div>
    </div>
}

const SiteHeader = ({label = "", groupData = [], currentSelection=[], createService, serviceCheckAction}) => {
    const boundMagic = useContext(DataContext);
    
    if(groupData.length == 0){
        return <span key="no_site">No Site</span>
    }

    const data = groupData[0].basis.fullEntity;

    const clickCreateService = () => {
        if(createService){
            createService(data.site_customer, data);
        }
    };

    const services = _.map(_.filter(data.services || [], (s) => s.historical == 0 ), (service) => { return <ServiceData data={service} label={service.friendly_id} key={service.id} serviceCheckAction={serviceCheckAction} />; });

    const isChecked = checkIsChecked(groupData, currentSelection);

    return <div key={data.id} className="site-header">
        <div>
            {serviceCheckAction == undefined? <span className="check-site"><input type="checkbox" checked={isChecked} onChange={(e)=>checkSite(groupData,boundMagic,e)} data-site-id={data.site_friendly_id} /></span> : null}
            <span className="title"><TitleLabel label={label} name={data.site_name}/>,  <NiceAddress address={data.site_address} city={data.site_city} province={data.site_province} postalCode={data.site_postal_code} /></span>
            <span className="create-button"><Button onClick={()=>clickCreateService()} className="add-button service-button">Add Service</Button></span>
        </div>
        {services}
    </div>
}

const ClientHeader = ({label = "", groupData = [], currentSelection = [], createSite = undefined, serviceCheckAction}) => {
    const boundMagic = useContext(DataContext);

    if(groupData.length == 0){
        return <span key="no_client">No Client</span>
    }

    const data = groupData[0].basis.fullEntity;

    const clickCreateSite = () => {
        if(createSite != undefined){
            createSite(data.customer);
        }
    };

    const isChecked = checkIsChecked(groupData, currentSelection);

    return <div key={"client_" + data.name} className="client-header">
        <div>
            {serviceCheckAction == undefined? <span className="check-site"><input type="checkbox" checked={isChecked} onChange={(e)=>checkSite(groupData,boundMagic,e)} data-client-id={data.friendly_id} /></span> : null}
            <span className="title"><TitleLabel label={label} name={data.customerName}/></span>
            <span className="create-button"><Button onClick={()=>clickCreateSite()} className="add-button site-button">Create Site</Button></span>
        </div>
    </div>
}

const SelectorResults = ({data, currentSelection, groups, createClient, createSite, createService, serviceCheckAction}) => {
    const boundMagic = useContext(DataContext);
    const [myData, setMyData] = useState([]);
    
    useEffect(()=>{
        setMyData(data); //_.unionBy(boundMagic.magicalGet("sites", []), data, "key")
    },[currentSelection, data]);

    return <div key="theKey">
        <Button className="add-button client-button" onClick={()=>createClient()}>Create Client</Button>
        <GroupBreakdown currentSelection={currentSelection} groups={groups} data={myData} level={0} defaultProps={{createSite: createSite, createService: createService, serviceCheckAction: (service)=>serviceCheckAction(myData, service)}} />
    </div>
}

const CreateClientWorkflow = ({siteCreatedCallback = undefined, serviceCreateCallback = undefined, initialClientId = 0, initialSite = undefined}) =>{
    const [workflowContextState, setWorkflowContextState] = useState({onStepFinish: undefined, onGoBack: undefined});
    const [clientId, setClientId] = useState(initialClientId);
    const [site, setSite] = useState(initialSite);
    const currentModal = useContext(CurrentModal);

    const initialize = (context) => {
        setWorkflowContextState(context);
    }

    const clientAfterSave = (client) =>{
        if(client.id <= 0){
            return;
        }

        setClientId(client.id);

        if(workflowContextState.onStepFinish != undefined){
            workflowContextState.onStepFinish();
        }
    }
    
    const siteAfterSave = (site) =>{
        if (siteCreatedCallback){
            siteCreatedCallback( site );
        }

        APP.instance.closeModal( currentModal != undefined && currentModal.id != undefined? currentModal.id : undefined );
    }

    const serviceAfterSave = (service) => {
        if(serviceCreateCallback){
            serviceCreateCallback(service);
        }
        
        APP.instance.closeModal( currentModal != undefined && currentModal.id != undefined? currentModal.id : undefined );
    };

    return <div key="create_client_workflow" className="create-client-workflow">
        <Workflow initialize={(context) => initialize(context)} initialStep={ initialSite != undefined ? 3 : (initialClientId > 0 ? 2 : 1)}>
            <Step myStepNumber={1}>
                <div className="client-edit-wrapper">
                    <PageHeading>Create Client</PageHeading>
                    <ClientEdit id={0} afterSave={(client)=>clientAfterSave(client)} saveButtonText="Next Step"/>
                </div>
            </Step>
            <Step myStepNumber={2}>
                <div className="site-edit-wrapper">
                    <PageHeading>Create Site</PageHeading>
                    <SiteEdit id={0} defaults={{customer: clientId}} afterSave={(site)=>siteAfterSave(site)}/>
                </div>
            </Step>
            <Step myStepNumber={3}>
                <div className="service-edit-wrapper">
                    <PageHeading>Create Service</PageHeading>
                    <EditService id={0} defaults={{customer: clientId, site: site}} afterSave={(service)=>serviceAfterSave(service)}/>
                </div>
            </Step>
        </Workflow>
    </div>
}

const ClientSiteSelector = ({boundTo = {sites: [], searchResult: undefined}, onDoStuff, single = false, labelText = "Apply", serviceCheckAction = undefined}) => {
    const [boundMagic, setBoundMagic] = useState(null);
    const [currentSelection, setCurrentSelection] = useState([]);
    const currentModal = useContext(CurrentModal);
    const [refreshCount, setRefreshCount] = useState(0);
    
    useEffect( ()=>{
        if (boundMagic != null){
            boundMagic.on('sites',(a,b,c)=>{
                setCurrentSelection(_.map(a,'key'));
            });

            boundMagic.magicalSet("single", single);
        }
    },[boundMagic]);

    const groups = [
        new Group("client", (autoCompleteResult) => "Client " + autoCompleteResult.basis.fullEntity.friendly_id, ClientHeader),
        new Group("site", (autoCompleteResult) => "Site " + (autoCompleteResult == undefined? "No Site" : autoCompleteResult.basis.fullEntity.site_friendly_id), SiteHeader)
    ];

    const doApply = () => {
        const doStuffResult = onDoStuff(boundTo.sites);
        if(doStuffResult == undefined || doStuffResult == true){
            APP.instance.closeModal( currentModal != undefined && currentModal.id != undefined? currentModal.id : undefined );
        }
    }

    const siteCreatedCallback = (site)=>{
        APP.central.ClientContract.SingleClientAndSiteAutocomplete(site.id).then( (r)=>{
            if (r.result != null){
                const currentSites = boundMagic.magicalGet("single", false) ? [] : boundMagic.magicalGet("sites", []);
                let newSites = _.unionBy( currentSites, [r.result], 'key');
                boundMagic.magicalSet("sites", newSites);
            }
        });
    }

    const serviceCreateCallback = (service) => {
        setRefreshCount((c)=>c+1);
        siteCreatedCallback(service.site);
    }

    const createClient = () => {        
        APP.instance.createModal(<CreateClientWorkflow siteCreatedCallback={siteCreatedCallback}/>, {modal_name: "New Client"});
    }

    const createSite = (clientId) => {
        APP.instance.createModal(<CreateClientWorkflow siteCreatedCallback={siteCreatedCallback} initialClientId={clientId}/>, {modal_name: "New Site"});
    }

    const createService = (clientId, site) => {
        APP.instance.createModal(<CreateClientWorkflow serviceCreateCallback={serviceCreateCallback} initialClientId={clientId} initialSite={site}/>, {modal_name: "New Service"});
    }

    const serviceAction = (selectorData, selectedService) =>{
        serviceCheckAction(selectorData, selectedService);
    }
        
    return <div key="client-site-selector" className="client-site-selector">
        <Bound to={boundTo} init={(bound)=>setBoundMagic(bound)}>
            {currentSelection.length} Selected
            <InputAutoComplete field="searchResult" name="Client and Site" central={(search, shouldExpand, searchById)=>APP.central.ClientContract.NewClientAndSiteAutocomplete(currentSelection,search, shouldExpand, searchById)} dataCallback={(data) => <SelectorResults data={data} currentSelection={currentSelection} groups={groups} createClient={createClient} createSite={createSite} createService={createService} serviceCheckAction={(data, selectedService)=>serviceAction(data, selectedService)}/> } refresh={refreshCount}/>
        </Bound>

        {serviceCheckAction == undefined? <Button className="apply-sites" onClick={()=>doApply()}>{labelText}</Button> : null}
    </div>
}

const ClientSiteServiceSelector = ({callback = (service, site, client) => { console.log("No callback setup for ClientSiteServiceSelector"); return false; }, labelText = "Apply", serviceCheckable = false}) =>{
    const boundMagic = useContext(DataContext);
    const currentModal = useContext(CurrentModal);
    const boundToRef = useRef({sites: [], searchResult: undefined});
    const [services, setServices] = useState([]);
    const client = useRef(null);
    const site = useRef(null);
    const service = useRef(null);
    const [workflowContextState, setWorkflowContextState] = useState({onStepFinish: undefined, onGoBack: undefined});

    const initialize = (context) => {
        setWorkflowContextState(context);
    }

    const doStuff = (sites) =>{
        if(sites != undefined && sites.length > 0){
            const siteEntity = sites[0].basis.fullEntity;
            site.current = siteEntity;

            if(siteEntity.Customer != undefined){
                client.current = siteEntity.Customer; //object
            } else if(siteEntity.customer != undefined){
                client.current = siteEntity.customer; //integer
            }

            if(siteEntity.services != undefined && siteEntity.services.length > 0){
                setServices(siteEntity.services);
                workflowContextState.onStepFinish();
            }
        }

        return false;
    };

    const updateService = (bound, field, value) =>{
        service.current = _.find(services, (s)=>s.id == value);
    }

    const applyValues = ()=>{
        if(service.current == null || service.current == ""){
            APP.instance.openConfirmDialog("No service selected.");
        } else {
            const closeModal = callback(service.current, site.current, client.current);
            if (closeModal){
                APP.instance.closeModal( currentModal != undefined && currentModal.id != undefined? currentModal.id : undefined );
            }
        }
    }

    const serviceCheckAction = (data, selectedService) =>{
        if(serviceCheckable){
            service.current = selectedService;

            const selectedSiteResult = _.find(data, {key: selectedService.site + ""});
            if(selectedSiteResult != undefined){
                site.current = selectedSiteResult.basis.fullEntity;

                if(site.current != undefined && site.current.Customer != undefined){
                    client.current = site.current.Customer;
                }
            }
            applyValues();
        } else {
            console.log("ClientSiteSelector.jsx: serviceCheckable prop must be set to true to enable this function");
        }
    }

    const goBack = () =>{
        if(workflowContextState.onGoBack != undefined){
            boundToRef.current.sites = [];
            setServices([]);
            workflowContextState.onGoBack();
        }
    }

    return <div key="client-site-service-selector" className="client-site-service-selector">
        <Workflow initialize={(context) => initialize(context)}>
            <Step myStepNumber={1}>
                <div className="client-site-selector-wrapper">
                    <PageHeading>Select Site</PageHeading>
                    <ClientSiteSelector boundTo={boundToRef.current} onDoStuff={(sites)=>doStuff(sites)} single={true} labelText={labelText} serviceCheckAction={serviceCheckable? serviceCheckAction : undefined}/>
                </div>
            </Step>
            <Step myStepNumber={2}>
                <div className="service-selector-wrapper">
                    <PageHeading>Select Service</PageHeading>
                    <div className="current-selections">
                        <div>
                            <strong>Client: </strong>
                            {client.current != undefined? client.current.name : ""}
                        </div>
                        <div>
                            <strong>Site: </strong>
                            {site.current != undefined? site.current.name : ""}
                        </div>
                    </div>
                    <Bound to={boundMagic.to}>
                        <InputSelect options={_.fromPairs(services.map((s)=>[s.id, s.description]))} onChange={(bound, field, value)=>updateService(bound, field, value)} showEmpty={true}/>
                    </Bound>
                    <Button className="apply-service" onClick={()=>applyValues()}>{labelText}</Button>
                    <Button className="apply-service" onClick={()=>goBack()}>Back</Button>
                </div>
            </Step>
        </Workflow>
    </div>
}


// TODO - WIP use this to replace ClientSiteSelector and ClientSiteServiceSelector when its ready
// use new methods to query the API for the data instead of using the autocomplete
const FastClientSiteServiceSelector = ({
  callback = (service, site, client) => {   
    return false;
  },
  labelText = "Apply",
}) => {
  const client = useRef(null);
  const site = useRef(null);
  const service = useRef(null);

  return (
    <div key="client-site-service-selector" className="client-site-service-selector">
      WIP
    </div>
  );
};


export {ClientSiteServiceSelector, FastClientSiteServiceSelector};
export default ClientSiteSelector;