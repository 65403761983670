import React, { Fragment, useContext, useEffect, useRef, useState } from "react";

import BoundCommit from "@opidcore/components/BoundCommit";
import {
  BoundDataContext,
  Button,
  FlexBreak,
  FlexRow,
  Icon,
  InputText,
  InputTextPattern,
  InputToggleSwitch,
  Loading,
  TabularColumn,
  TabularDataSet,
  UnlockToEdit,
  useMagic,
} from "@opidcore/components";
import * as _ from "lodash";
import LookupInputSelect from "@opidcore/components/LookupInputSelect";
import { APP } from "../../../../js";
import MultiThingSelect from "../../components/MultiThingSelect";
import EditPortalProfile from "./EditPortalProfile";
import EditClientContactList from "./EditClientContactList";
import { useData } from "@opidcore/hooks/WTF";
import { CurrentModal } from "@opidcore/components/OpidApplication";

export default function EditContact(props) {
  const contact = useMagic({});
  const site = props.site ? props.site : null;

  const [contactListData, contactListDataSet] = useData("contact-" + ((contact.to && contact.to.id) ? contact.id : props.contactId), APP.central.CustomerContact.fetchContactLists, {
    contactId: (contact.to && contact.to.id) ? contact.id : props.contactId,
  });

  const [showRoleSelect, setShowRoleSelect] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    APP.central.CustomerContact.fetchForEdit(props.contactId).then((r) => {
      contact.replaceTo(r.result);

      setLoading(false);
      if (props.customer) {
        setLoading(true);
        APP.central.Customer.fetch(props.customer).then((cust) => {
          contact.magicalSet("customer", cust.result);
          contact.magicalSet("customerId", cust.result.id);
          setLoading(false);
        });
      }
    });
  }, []);

  const invite = () => {
    APP.central.Portal.inviteToPortal(contact.to.id, 1).then(() => {
      APP.alert("Invite sent!");
    });
  };

  const impersonate = () => {
    APP.central.Portal.impersonatePortal(contact.to.id, 1).then((r) => {
      window.open(r.result.portalUrl, "_blank");
    });
  };

  const createNewPortalProfile = () => {
    APP.instance.createModal(<EditPortalProfile portalProfileSettingsId={0} afterSave={(savedProfile) => assignProfile(savedProfile)} />, {
      modal_name: "Edit Portal Profile",
    });
  };

  const viewPortalProfile = () => {
    APP.instance.createModal(<EditPortalProfile portalProfileSettingsId={contact.to.portalProfileSettings.id} skipSave={true} />, {
      modal_name: "View Portal Profile",
    });
  };

  const assignProfile = (profile) => {
    _GLOBAL_DATA_CACHE["portal_profile_settings;lookup"].fetch();
    APP.registeredBoundMagics["editcontact-" + contact.to.id].magicalSet("portalProfileSettings", profile);
  };

  const newContactList = () => {
    APP.central.CustomerContact.createContactList(contact.to.id).then((r) => {
      contactListDataSet.fetch();
    });
  };

  const deleteContactList = (contactListId) => {
    APP.confirm("Deleting is permanent. Continue?", (confirmResult) => {
      if (confirmResult == true) {
        APP.central.CustomerContact.deleteContactList(contactListId).then((r) => {
          contactListDataSet.fetch();
        });
      }
    });
  };

  const editContactList = (contactList) => {
    APP.instance.createModal(<EditClientContactList data={contactList} />);
  };

  const doSetShowRoleSelect = (value) => {
    if(contact && contact.to.id == 0) {
      APP.alert("Please save the new contact before editing roles.");
      return;
    }
    setShowRoleSelect(value);
  }

  const doAfterSave = (savedContact) => {
    contact.replaceTo(savedContact);
    if(props.contactId == 0 && props.site && props.site.id) {
      APP.central.CustomerContact.createPrepopContactList(savedContact.id, props.site.id).then((r) => {
        let newQueryArgs = contactListDataSet.queryArgs;
        newQueryArgs.contactId = savedContact.id;
        contactListDataSet.setQueryArgs(newQueryArgs);
        contactListDataSet.reset();
        contactListDataSet.fetch();
      });
    }
  }


  return (
    <div className="basicForm edit-client-contact">
      <BoundCommit initialValue={contact} commit={APP.central.CustomerContact.saveChanges} boundId={"editcontact-" + contact.id} afterSave={doAfterSave}>
        <h3>Edit Contact</h3>

        {loading ? <Loading/> : <LookupInputSelect what="customer" field="customer" name="Client" showEmpty={true} />}
        <Button className="smallButton" onClick={invite}>
          <Icon icon="envelope" />
          Invite to Portal
        </Button>
        <Button className="smallButton" onClick={impersonate}>
          <Icon icon="user" />
          Impersonate Portal
        </Button>

        <FlexBreak />
        <InputTextPattern pattern={"^[a-zA-Z0-9ÀÁÂÈÉÊËàáâ–ééêë\u0020\u00c7\u00e7\-]{0,}$"} field="firstName" name="First Name" required={true} />
        <InputTextPattern pattern={"^[a-zA-Z0-9ÀÁÂÈÉÊËàáâ–ééêë\u0020\u00c7\u00e7\-]{0,}$"}  field="lastName" name="Last Name" required={true}/>
        <FlexBreak />
        <InputTextPattern pattern={"^[a-zA-Z0-9 –\-]{0,}$"} field="title" name="Title" required={false}/>

        {showRoleSelect ? (
          <ContactRoleSelect setShowRoleSelect={setShowRoleSelect} />  
        ) : (
          <Fragment>
            <UnlockToEdit>
              <InputText
                field="rolesString"
                name={
                  <Fragment>
                    Roles
                    <Icon icon="edit" size="1x" onClick={() => doSetShowRoleSelect(true)} title="Edit Roles" />
                  </Fragment>
                }
              />
            </UnlockToEdit>
          </Fragment>
        )} 

        <FlexBreak />
        <InputTextPattern pattern={"^[_A-Za-z0-9-ÀÁÂÈÉÊËàáâééêë\u00c7\u00e7\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-ÀÁÂÈÉÊËàáâééêë\u00c7\u00e7]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"} field="email" name="Email"  message={"email@example.com"}/>
        <InputText field="phone" name="Phone Number" />
        <InputText field="cellPhone" name="Mobile Number" />
        {/*
        <AddressBlock>
          <InputText field="addressLine1" name="Address Line 1" />  
          <InputText field="addressLine2" name="Address Line 2" />
          <InputText field="city" name="City" />
          <InputText field="province" name="Province" />
          <InputText field="postalCode" name="Postal Code" />
        </AddressBlock>
        */}

        <h3>Email Settings</h3>
        <FlexRow>
          <InputToggleSwitch
            field="receivesInvoices"
            name={(status) => {
              return (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <Icon icon="dollar-sign" size="2x" />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    Receives Invoices <SiteLimiter field="invoiceSites"/>
                  </div>
                </span>
              );
            }}
          />
          <InputToggleSwitch
            field="receivesCostSavings"
            name={(status) => {
              return (
                <span>
                  <Icon icon="analytics" size="2x" />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    Receives Monthly Reports <SiteLimiter field="reportSites"/>
                  </div>
                </span>
              );
            }}
          />
          <InputToggleSwitch
            field="receivesSustainability"
            name={(status) => {
              return (
                <span>
                  <Icon icon="leaf" size="2x" />
                  Receives Sustainability
                </span>
              );
            }}
          />
          <InputToggleSwitch
            field="marketingDoNotEmail"
            name={(status) => {
              return (
                <span>
                  <Icon icon="envelope" size="2x" />
                  Does Not Receive Marketing Emails
                </span>
              );
            }}
          />
          <InputToggleSwitch
            field="greaseTrapContact"
            name={(status) => {
              return (
                <span>
                  <Icon icon="french-fries" size="2x" />
                  Receives Grease Trap Emails
                </span>
              );
            }}
          />
        </FlexRow>

        <div className="contact-list-list">
          <h3>Contact Lists</h3>
          <Button className="add-btn" onClick={() => newContactList()}>
            Add
          </Button>
          <TabularDataSet dataSet={contactListDataSet} noData="No Contact Lists">
            <TabularColumn title="Client" data={(row) => row.customerName} />
            <TabularColumn title="Umbrella" data={(row) => row.umbrella} />
            <TabularColumn title="Site" data={(row) => row.siteName} />
            <TabularColumn title="" data={(row) => <Icon icon="pencil" onClick={() => editContactList(row)} />} />
            <TabularColumn title="" data={(row) => <Icon icon="trash" onClick={() => deleteContactList(row.id)} />} />
          </TabularDataSet>
        </div>

        <h3>Portal Settings</h3>
        <FlexRow>
          <div style={{ display: "flex", flexDirection: "row", width: "fit-content", alignItems: "flex-end" }}>
            <LookupInputSelect
              what="portal_profile_settings"
              display="profileName"
              field="portalProfileSettings"
              name="Portal Profile"
              showEmpty={true}
              store="object"
            />
            <Icon icon="eye" onClick={() => viewPortalProfile()} style={{ height: 40, margin: "5px 2px" }} />
          </div>
          <Button className="smallButton" onClick={() => createNewPortalProfile()}>New Profile</Button>
        </FlexRow>
      </BoundCommit>
    </div>
  );
}

const ContactRoleSelect = ({ setShowRoleSelect }) => {
  const bound = useContext(BoundDataContext);
  const originalRoles = _.map(bound.magicalGet("roles", []), (role) => role.roleLookup);
  const [roles, setRoles] = useState(originalRoles);
  const [saving, setSaving] = useState(false);

  const doAssignRoles = () => {
    setSaving(true);
    const rolesToAssign = _.map(roles, (role) => role.id);
    const contactId = bound.magicalGet("id");

    APP.central.CustomerContact.assignRoles(contactId, rolesToAssign).then((r) => {
      setSaving(false);
      const newBoundTo = _.cloneDeep(bound.to);
      newBoundTo.roles = r.result.roles;
      newBoundTo.rolesString = r.result.rolesString;
      bound.replaceTo(newBoundTo);
    });
  };

  return (
    <div className="contactRoleSelect">
      <label>Roles</label>
      <MultiThingSelect
        thing="lookup:contactRole"
        setter={setRoles}
        existing={originalRoles}
        addButtonLabel="Show Assignable Roles"
        columns={["label"]}
        showSearch={false}
        defaultShowList={true}
      />
      {saving ? (
        <Loading />
      ) : (
        <Fragment>
          <Button onClick={() => doAssignRoles()}>Assign Roles</Button> <Button onClick={() => setShowRoleSelect(false)}>Stop Editing Roles</Button>
        </Fragment>
      )}
    </div>
  );
};

function ManageSitesField({ sites, contact }) {
  const editSites = () => {
    APP.instance.createModal(<ManageSites contact={contact} sites={sites} />, { modal_name: "Assign Sites" });
  };

  return (
    <div className="field" onClick={editSites}>
      <label>Sites</label>
      <div className="value">{sites != null ? sites.length : 0} Visible Sites</div>
    </div>
  );
}

function ManageSites({ sites, contact }) {
  const [siteList, setSiteList] = useState(sites);

  const setSites = () => {};

  const addRule = () => {};

  return (
    <div className="field basicForm">
      <h3>
        {contact.firstName} {contact.lastName} - Settings
      </h3>
      <Button onClick={addRule}>Add Rule</Button>

      <MultiThingSelect thing="site" setter={setSiteList} existing={sites}></MultiThingSelect>
      <Button onClick={() => setSites()}>Assign Sites?</Button>
    </div>
  );
}

const SiteLimiter = ({field}) => {
  const boundMagic = useContext(BoundDataContext);
  const [displayValue, setDisplayValue] = useState("All");

  useEffect(() => {
    boundMagic.magicalState(field, (val) => {
      if (val == undefined || val == "") {
        val = [];
      }

      val = _.reject(val, { id: 0 });

      if (val.length == 0) {
        setDisplayValue("All");
      } else {
        setDisplayValue(val.length);
      }
    });
  }, [boundMagic]);

  const openSiteSelection = () => {
    const initialValue = boundMagic.magicalGet(field, []);
    APP.instance.createModal(<SiteSelector clientId={boundMagic.magicalGet("customer", {}).id} boundMagic={boundMagic} field={field} />, { modal_name: "Select Site" });
  };

  return (
    <div style={{ marginLeft: 10 }}>
      {displayValue} <Icon icon="pencil" onClick={() => openSiteSelection()} />
    </div>
  );
};

const SiteSelector = ({ clientId, boundMagic, field }) => {
  const modalContext = useContext(CurrentModal);
  const [data, dataSet] = useData("sites-" + clientId, APP.central.Customer.fetchSiteList, { id: clientId });
  const [sites, setSites] = useState([]);
  const [checkedSites, setCheckedSites] = useState({});
  const tempSites = useRef(boundMagic.magicalGet(field, []));

  useEffect(() => {
    if (dataSet.loading == false) {
      setSites(
        _.map(data, (d) => {
          return d;
        })
      );
    }
  }, [dataSet.loading]);

  useEffect(() => {
    updateSelections({ id: 0 });
  }, [sites]);

  const updateSelections = (site) => {
    let currentSites = tempSites.current;
    const lengthBefore = currentSites.length + 0;
    currentSites = _.filter(currentSites, (id) => id != site.id);
    const lengthAfter = currentSites.length + 0;

    if (lengthBefore == lengthAfter) {
      //add because nothing was removed so it wasn't in the list
      if (lengthBefore == 0) {
        //none are selected so all but 'site' need to be selected
        _.forEach(data, (d) => {
          if (d.id != site.id) {
            currentSites.push(d.id);
          }
        });
      } else {
        currentSites.push(site.id);
      }
    }

    currentSites = _.filter(currentSites, (id) => id != 0);
    if (currentSites.length == data.length) {
      currentSites = [];
    }

    tempSites.current = currentSites;
    updateChecked();
  };

  const selectAll = () => {
    tempSites.current = [];
    updateChecked();
  };

  const removeAll = () => {
    tempSites.current = [0];
    updateChecked();
  };

  const updateChecked = () => {
    const newChecked = { ...checkedSites };
    let theTempSites = {};

    _.forEach(tempSites.current, (id) => {
      theTempSites[id] = "seen";
    });

    _.forEach(data, (s) => {
      if ((theTempSites[s.id] != undefined && theTempSites[0] == undefined) || _.isEmpty(theTempSites)) {
        newChecked[s.id] = true;
      } else {
        newChecked[s.id] = false;
      }
    });

    setCheckedSites(newChecked);
  };

  const apply = () => {
    const currentData = _.filter(tempSites.current, (id) => id != 0);
    if (currentData.length == 0 || currentData.length == data.length) {
      tempSites.current = [];
    }

    boundMagic.magicalSet(field, tempSites.current);

    APP.instance.closeModal(modalContext.id);
  };

  if (clientId == undefined || clientId == 0 || clientId == "") {
    return null;
  }

  const siteToggles = _.map(sites, (s) => {
    return (
      <div className="field site-option" key={s.id}>
        <label className="site-option-label site-option-label-100">{s.friendly_id}</label>
        <label className="site-option-label site-option-label-200">{s.address}</label>
        <label className="site-option-label site-option-label-400">{s.name}</label>
        <input type="checkbox" className="form-checkbox" onChange={() => updateSelections(s)} checked={checkedSites[s.id] == true} />
      </div>
    );
  });

  return (
    <div className="contact-site-selector">
      <div className="site-selector-actions">
        <label className="site-selector-action" onClick={() => selectAll()}>
          Select All
        </label>
        <label className="site-selector-action" onClick={() => removeAll()}>
          Remove All
        </label>
        <label className="site-selector-action" onClick={() => apply()}>
          Apply
        </label>
      </div>
      {siteToggles}
    </div>
  );
};