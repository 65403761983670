import React, { useState, useEffect, useRef, useContext }  from 'react';
import Bound, {DataContext} from './Bound';
import SaveToolbar from '@opidcore/components/SaveToolbar';
import {CurrentModal} from '@opidcore/components/OpidApplication';
import { useRouteMatch } from "react-router-dom";
import PropTypes from 'prop-types';

/**
 * 
 * @param {} props 
 * @returns
 * 
 * @example
 * if you are creating a new record, __new:true will force delta to be all columns passed in
 * __dataUID can be set to update any listening data caches 
 */
export default function BoundCommit(props){
	const boundContext = useContext(DataContext);
	const initialRecord = useRef(props.initialValue);
	const boundRef = useRef({_myMagic: undefined});
	const match = useRouteMatch();
	const inAModal = useContext(CurrentModal);
	const [saveMessage, setSaveMessage] = useState("");
	
	
	useEffect( ()=>{
		if (props.stealBound){
			props.stealBound(boundRef);
		}
		
		return ()=>{
			//unregister my bound because I am unmounting
			if(props.boundId){
				APP.unregisterBoundMagic(props.boundId);
				
				//unregister my children as well
				_.forEach(APP.registeredBoundMagics, (rbm, rbmKey)=>{
					if(rbmKey.indexOf(props.boundId + "|") > -1){
						//console.log("unregistering bound commit " + rbmKey);
						APP.unregisterBoundMagic(rbmKey);
					}
				});
			} else {
				//we don't have a way of knowing if something needs unregistering
			}
		}
	}, [] );
	
	
	useEffect(()=>{
		if(Array.isArray(props.initialValue)){
			initialRecord.current = {...props.initialValue[0]};
		} else {
			initialRecord.current = {...props.initialValue};
			if(props.initialValue == undefined){
				//alert("You shound send me an initialValue");
			}
		}

		//boundRef.current._myMagic.to = initialRecord.current;
		boundRef.current.replaceMagicTo(initialRecord.current);
	}, [props.initialValue]);
	
	useEffect(()=>{
		console.log('BoundCommit.js: useEffect(): ' + APP.getState("displayMessage") + " " + window.FETCH_REMAINING);
		//setSaveMessage(APP.getState("displayMessage") + "" + window.FETCH_REMAINING);
		
	}, [APP.getState("displayMessage"), window.FETCH_REMAINING]);

	const checkContractTerm = (term)=>{
		const termInt = parseInt(term);		
		if(! termInt || termInt <=0)
		{ return false;	}
		return true;		
	}

	const checkContractRequiredFields = (props) =>{
		let requiredOK = true;
		if(props.commit.source === "ClientContract" || props.commit.source === "VendorContract"){			
			const expiryPeriod = parseInt(props.to.to.expiryPeriod);
			if(!expiryPeriod || expiryPeriod <= 0){
				APP.alert("Failed to Save. Please enter a positive numeric value for Expiry Warning Period field.");
				requiredOK = false;
			}
			if(!checkContractTerm(props.to.to.contractTerm)){
				APP.alert("Failed to Save. Please enter a positive numeric value for the Term field");   
				requiredOK =  false; 
			}			
		}
		return requiredOK;		
	}

	const isNameFormatCorrect = (name) =>{
		if(!name){return false} 
		if(name && name.trim().length == 0){return false}
        let regexp = "^[a-zA-Z0-9ÀÁÂÈÉÊËàáâ–ééêë\u0020\u00c7\u00e7\-]{0,}$"; 
		return (name.match(regexp) != null); 
	}

	const isTitleFormatCorrect = (title) =>{		 
        let regexp = "^[a-zA-Z0-9 –\-]{0,}$";
		return title.match(regexp) != null; 
	}

	const isEmailFormatCorrect = (email) =>{ 
		let regexp = "^[_A-Za-z0-9-ÀÁÂÈÉÊËàáâééêë\u00c7\u00e7\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-ÀÁÂÈÉÊËàáâééêë\u00c7\u00e7]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"; 
		return email.match(regexp) != null; 
	}

	const checkContactFieldsFormat = (props) => {
		let contactFieldsOK = true; 
		if(props.commit.source === "CustomerContact"){
			if(!isNameFormatCorrect(props.initialValue.to.firstName) || !isNameFormatCorrect(props.initialValue.to.lastName)){
				APP.alert("Failed to Save. Please use the correct format for the name");
				contactFieldsOK = false;
			}
			if(props.initialValue.to.title && !isTitleFormatCorrect(props.initialValue.to.title) ){ 
				APP.alert("Failed to Save. Please use the correct format for the title");
				contactFieldsOK = false;
			}
			if(props.initialValue.to.email && !isEmailFormatCorrect(props.initialValue.to.email)){
				APP.alert("Failed to Save. Please use the correct format for the email"); 
				contactFieldsOK = false;

			}
			if(!props.initialValue.to.email && ! props.initialValue.to.phone && ! props.initialValue.to.cellPhone){
				APP.alert("Failed to Save. Either email, phone number, or mobile number must be entered for the contact.");   
				contactFieldsOK = false; 
			}
			
		}
		return contactFieldsOK; 
	}

	const datesOkForEachLine = (line)=>{ 
		let dateOkForLine = true;
		if(line.items && line.items.length > 0){
			var dateReg = /^\d{4}-\d{2}-\d{2}$/;		
			if(line.items[0].periodTo && line.items[0].periodFrom){
				if(! line.items[0].periodFrom.match(dateReg) || ! line.items[0].periodTo.match(dateReg)){
					dateOkForLine = false;
				}
				let fromDate = new Date(line.items[0].periodFrom)
				let toDate = new Date(line.items[0].periodTo)
				if(fromDate > toDate){
					dateOkForLine = false; 
				} 
			}
			else{
				dateOkForLine = false;
			}
		}
		
		return dateOkForLine;
	}

	const mandatoryFieldsOkForEachLine = (line)=>{ 
		let requiredFieldsOk = true;
		if(line.items && line.items.length > 0){
			if(! line.items[0].site || (line.items[0].site && ! line.items[0].site.address) 
				|| line.items[0].service == 0  || (line.items[0].service != 0 && ! line.items[0].service) 
			    || ! line.items[0].vendorActivity || ! line.items[0].periodFrom 
			    || ! line.items[0].periodTo || (line.items[0].quantity != 0 && ! line.items[0].quantity) 
				|| (line.items[0].unitPrice != 0 && ! line.items[0].unitPrice) 
				|| !line.items[0].unitPricePer || !line.items[0].taxId){   
				requiredFieldsOk = false;   
			}
		}		
		return requiredFieldsOk;
	}

	const invoiceLinesRequiredFieldsOk = (props)=>{  
		let requiredFieldsOk = props.initialValue.lines.every(mandatoryFieldsOkForEachLine) 
		return requiredFieldsOk;
	}

	const invoiceDatesOk=(props)=>{		
		let datesOk = props.initialValue.lines.every(datesOkForEachLine)
		return datesOk;
	}

	const checkAPLinesFields = (props) =>{ 
		let invoiceLineFieldsOk = true;
		if( props.initialValue && props.initialValue.lines && props.initialValue.lines.length > 0){ 
			
		    if(!invoiceDatesOk(props)){
			   invoiceLineFieldsOk = false;
			   APP.alert("Failed to Save. Make sure the From and To dates are entered and correct for all the invoice lines.");
		    }
			else if(! invoiceLinesRequiredFieldsOk(props)){ 
				invoiceLineFieldsOk = false;
				APP.alert("Failed to Save. Make sure all the required fields have values for all the invoice lines.");   
			}
		}
		return invoiceLineFieldsOk;		
	}

	const checkAPBillingFields = (props)=>{ 
		let clientFieldsOk = true;
		let yearMonthDay = /^\d{4}-\d{2}-\d{2}$/;	 
		let yearMonth = /^\d{4}-\d{2}$/;
		if(props.toolbar && props.toolbar.props && props.toolbar.props.magic && props.toolbar.props.magic.props &&  props.toolbar.props.magic.props.to){
			let to = props.toolbar.props.magic.props.to;
			if(to){				
					//invoice date, billing period, invoice number, status, invoice preparer, AP type, vendor tax total
				if(  ! to.invoiceDate || ! to.billingPeriod || ! to.externalInvoiceNumber  
					|| (to.externalInvoiceNumber && to.externalInvoiceNumber.length == 0)
					|| ! to.status || ! to.apType || to.vendorTaxTotal === "" ||  to.vendorTotal === ""){
						clientFieldsOk = false;
						APP.alert("Failed to Save. Make sure all the required fields have values.");    
				}
					//invoice date, date received , billing period 
				else if(((to.invoiceDate &&! to.invoiceDate.match(yearMonthDay)) 
					|| (to.dateReceived && ! to.dateReceived.match(yearMonthDay)) 
					|| (to.billingPeriod && ! to.billingPeriod.match(yearMonth))))    
				{
					clientFieldsOk = false;
					APP.alert("Failed to Save. Make sure values entered as date have correct format.");   
				}
			}		
		}
		 
		return clientFieldsOk; 
	}

	const checkAPInvoiceFields = (props) =>{
		let apFieldsOk = true;
		if(props.commit.source === "APInvoice"){
			if( ! checkAPLinesFields(props) || ! checkAPBillingFields(props)){ 
				apFieldsOk = false;
			}			
		}
		return apFieldsOk;		 
	}

	const doSave = ()=>{  		
		if (props.commit && !props.commitWithParent && checkContractRequiredFields(props) && checkContactFieldsFormat(props)
			 && checkAPInvoiceFields(props)){
			const delta = {... _.pick(props.initialValue, props.forceSaveProps), ...boundRef.current._myMagic.getDelta()};
			const requiredOk = boundRef.current._myMagic.requiredFieldsOk(); 
			if(!requiredOk){
				APP.alert("Failed to save. Please fill in all required fields.");
				throw new Error("Failed to save. Please fill in all required fields.");  
			} 				
			const commitHappened = props.commit(boundRef.current._myMagic.getIdOr(0), delta, boundRef.current._myMagic.to, boundRef.current._myMagic );
			if (commitHappened && commitHappened.then){
				return commitHappened.then((r)=>{ 
					if(props.dataSet && !Array.isArray(r.result)){ 
						props.dataSet.replace(r.result, "id");					
					}
					
					//check for modals with the same url and minimize them
					_.each(_.filter(APP.instance.state.modals, {path: match.url}), (modal)=>{
						if(match.params.id == 0 || boundRef.current._myMagic.to.id == 0 || props.closeOnCommit){ 
							//modal was a new record so we need to get rid of it
							APP.instance.closeModal(modal.id);
						} else {
							//modal was an existing record so we can just minimize it
							APP.instance.minimizeModal(modal.id);
						}
					});

					if(props.afterSave != undefined){
						props.afterSave(r.result);
					}
					
					if (r && r.result){	
						if(props.commit.source == "APInvoice"){
							APP.alert("Save/Update Success.");
						}					
						return r.result;	
					}				
				});
			}else{				
				APP.instance.closeModal(inAModal.id);

				if(props.afterSave != undefined){
					props.afterSave(boundRef.current._myMagic.to);
				}
			}						
		}else{
			//APP.alert("you should really set a commit prop");
		}
	}; 

 
	boundRef.current.__boundCommitDoSave = doSave;
	
	let toolbar = <SaveToolbar handleSave={doSave} message={saveMessage} key="savetoolbar" saveVerb={props.saveVerb}>{props.toolbar}</SaveToolbar>;
	if(props.commitWithParent || props.skipSave){
		toolbar = undefined;
	}

	let children = [];
	if(toolbar != undefined){
		if(!Array.isArray(props.children)){
			children = [props.children];
		} else {
			children = [...props.children];
		}
		children.push(toolbar);
	} else {
		children = props.children;
	}

	const disabled = props.disabled || props.skipSave || false;
	
	return <Bound ref={boundRef} className={props.className} disabled={disabled} flex={props.flex} listenTo={props.listenTo} to={props.to? props.to : initialRecord.current} onChange={props.onChange} commit={props.commit} commitWithParent={props.commitWithParent} boundId={props.boundId} permissions={props.permissions}>
		{children}
	</Bound>;
}

BoundCommit.propTypes = {
	boundId: PropTypes.string,
	initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	commit: PropTypes.func,
	commitWithParent: PropTypes.bool,
	flex: PropTypes.bool,
	children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
	dataSet: PropTypes.object,
	forceSaveProps: PropTypes.array,
	saveVerb: PropTypes.string,
	to: PropTypes.object,
	closeOnCommit: PropTypes.bool,
	afterSave: PropTypes.func,
	stealBound: PropTypes.func,
	className: PropTypes.string,
	permissions: PropTypes.object,
	skipSave: PropTypes.bool,
	disabled: PropTypes.bool
} 