import React, { useRef, useState } from "react"
import PeriodSelect from "../../components/PeriodSelect"
import { Bound, Button } from "@opidcore/components"
import moment from "moment"

export default function AutobillLog(props) {
    const [startPeriod, setStartPeriod] = useState(moment().format("YYYY-MM"))
    const [endPeriod, setEndPeriod] = useState(moment().format("YYYY-MM"))
    const [loading, setLoading] = useState(false)

    const stateRef = useRef({})

    const updateRef = (them, magic, key) => {
        setStartPeriod(stateRef.current.startPeriod)
        setEndPeriod(stateRef.current.endPeriod)
        setLoading(true)
    }

    const generateReport = () => {
        APP.central.AutobillLog.autobillExport(startPeriod, endPeriod).then((r) => {    
            APP.socket.send(JSON.stringify(r));
        })
    }

    return <div>
        <Bound to={stateRef.current} onChange={() => updateRef()}>
            <PeriodSelect field="startPeriod" name="Start Date"/>
            <PeriodSelect field="endPeriod" name="End Date"/>
            <Button onClick={() => generateReport()}>Run Report</Button>
        </Bound>
    </div>
}