import React, { useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { BoundDataContext, SurchargeInputSelect } from '@opidcore/components'; 
import * as _ from 'lodash';



export default function GlobalServiceInputSelect(props) {
	const bound = useContext(BoundDataContext);
    const [items, setItems] = useState([]);
    useEffect(()=>{
        setItems(props.globalServices)
    },[props.globalServices])
	
   	let field = props.what.indexOf(":") > 0 ? props.what.split(":")[1] : props.field;

	//if it's a path use just the last bit;
	if (field.indexOf(".") > 0) {
		field = _.last(field.split("."));
	}

	let onChange = undefined;

	let options = {};
	let optionsCollection = null;
	let defaultV = props.defaultValue;
	
    if (props.store == "object") {
        optionsCollection = items.length>0? items: props.globalServices;
    }

	if (props.store == "object") {
		onChange = (obj, field, value, context) => { 
			let id = value;
			if (typeof id == "object") {
				id = id.id;
			}

			let val = "";

			if (id > 0 && items != undefined && items.length > 0) {
				val = items.find((r) => r.id == id);
			} else if (id == "") {
				val = undefined;
			}

			if (val != "") {
				if (context == undefined) {
					if (bound != undefined) {
						bound.magicalSet(field, val);
					} else {
						console.log("no context found so we cannot set fields magically");
					}
				} else {
					context.magicalSet(field, val);
				}
			}

			if (props.onChange) {
				props.onChange(bound.to, field, value, context);
			}

			return false;
		}
	} else if (props.onChange) { 
		onChange = props.onChange;		
	}

	return <SurchargeInputSelect {...props} options={options} optionsCollection={optionsCollection} onChange={onChange} defaultValue={defaultV} />;
}

GlobalServiceInputSelect.propTypes = {
	componentName: PropTypes.string,
	componentType: PropTypes.string,
	/** Dataset to use for populating the select options.  It will be automatically fetched with useData. */
	what: PropTypes.string,
	/** display can be a string (showing that property), or a function (that will be passed the row). */
	display: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func
	]),
	/** function used to limit the options */
	filter: PropTypes.func,
	field: PropTypes.string,
	name: PropTypes.string,
	fetch: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.func
	]),
	disabled: PropTypes.bool,
	showEmpty: PropTypes.bool,
	store: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
	title: PropTypes.string,
	fetchOptions: PropTypes.object,
	bound: PropTypes.bool,
	defaultValue: PropTypes.any,
	forcedDefault: PropTypes.bool,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	parameter1: PropTypes.string,
	parameter2: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.any,
	labelExtra: PropTypes.any,
	appendable: PropTypes.bool,
	parent: PropTypes.string,
	emptyIsNull: PropTypes.bool
}

GlobalServiceInputSelect.defaultProps = {
	componentName: "LookupInputSelect",
	componentType: "BoundComponent",
	store: null,
	appendable: false
}