import React, { Component, PropTypes, useContext, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import BoundComponent from './BoundComponent';

import { DataContext } from './Bound';
import { useData } from '@opidcore/hooks/WTF';
import { InputTextWithSelect } from '.';
import Util from '@opidcore/Util';
import _ from 'lodash';

export default class MyDatePicker extends BoundComponent {

  static propTypes = {


  };

  static contextType = DataContext;

  constructor(props, context) {
    super(props, context);
    if (props.obj[props.field] != null) {
      this.state = {
        value: moment(props.obj[props.field])
      };
    } else if (props.obj != null && props.obj.state != null && props.obj.state[props.field] != null) {
      this.state = {
        value: moment(props.obj.state[props.field])
      };
    } else if (props.obj[props.field] != null) {
      this.state = {
        value: moment(props.obj[props.field])

      };
    } else {
      if (props.disableToday != true) {
        this.state = {
          value: moment()
        };
      } else {
        this.state = {
          value: null
        }
      }
    }

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.obj instanceof Component && nextProps.obj.state != null && nextProps.obj.state != undefined) {
      if (nextProps.obj.state[nextProps.field] != null) {
        this.setState({
          value: moment(nextProps.obj.state[nextProps.field])
        });
      } else {
        if (nextProps.disableToday != true) {
          this.setState({
            value: moment()
          });
          debugger;
          nextProps.update(nextProps.obj, nextProps.field, moment().format(nextProps.format), this.context);
        }
      }
    } else {
      if (nextProps.obj instanceof Object && nextProps.obj[nextProps.field] != undefined) {
        this.setState({
          value: moment(nextProps.obj[nextProps.field])
        });
      } else {
        //we just have no value from props       
        if (nextProps.defaultValue != undefined) {
          this.setState({
            value: moment(nextProps.defaultValue)
          });
        }
      }
    }
  }

  handleChange(e,a,b) {
    this.setState({ value: e });
    let formattedE = e;
    if (this.props.format != undefined) {
      formattedE = moment(e).format(this.props.format);
    } else {
      formattedE = moment(e,"YYYY-MM-DD", true).format("YYYY-MM-DD");
    }
    
    if (a != null && a.target && a.target.tagName == "INPUT") {
      if (a.target.value.length < 10) {
         //clear out date
        if (a.target.value.length == 0){
          formattedE = null;
        }else{
          return;
        }
      }
    }else{
      if (this.refs && this.refs.input){ 
        //if they clear it and click a cell this will happen
        if (a == null && this.refs.input.input.value == ''){
          formattedE = null;
        }
      } 
    }

    if (this.props.update) {
      this.props.update(this.props.obj, this.props.field, formattedE, this.context);
    }
    this.context.magicalSet(this.props.field, formattedE);    
  }

  handleBlur(e) {
    if (e.target.value.length < 10) {
      var val = e.target.value;
      val = val.replace(/[^0-9]/, '')
      var month = moment().format("MM");
      var day = moment().format("DD");
      if (val.substring(0, 2) <= 12) {
        month = val.substring(0, 2);
        day = val.substring(2, 4);
      }
      var year = moment().format("YYYY");

      var e = moment(month + '/' + day + '/' + year);
      this.refs.input.setSelected(e);
      //this.handleChange(e);
      
    }
  }

  gotAValue(value) {
    if (this.state.value != value) {
      this.setState({ value: value });
    }
  }

	doKeyDownEvent(e) {
		if (e.key == 'Tab') {
			setTimeout(() => {
				this.refs.input.setOpen(false);
			}, 100);
		}
	}

  getStyleForInvoiceDateField(props){
      if(this.props.invReqField && this.props.invoiceDate){
        return { 
          border: "1px solid red", borderRadius: "0.3rem" , width: "175px"
        }
      }
      else if (this.props.invReqField && !this.props.invoiceDate){
        return {
          border: "1px solid red", borderRadius: "0.3rem"
        }
      }
   }

  render() {
    let label = <label>{this.props.name} {this.renderLabelTags()}</label>;
    let input = "";

    let value = null;

    if (this.listeningForChanges != this.context && this.context.magicalState) {
      this.listeningForChanges = this.context;
      this.context.magicalState(this.props.field, (value) => this.gotAValue(value));
    }

    if (this.context.magicalGet(this.props.field) != null) {
      value = new moment(this.context.magicalGet(this.props.field));
    }
    //console.log("i am a date value", value);


    let selectedValue = null;
    if (value && value.toDate && value.toDate() != 'Invalid Date') {
      selectedValue = value.toDate();
    }
    if (selectedValue == null && this.props.defaultToday == true) { 
      selectedValue = new Date();
    }

    input = <DatePicker
      ref="input"
      className={"form-control"}
      selected={selectedValue}
      todayButton={"Today"}
      onBlur={(e) => this.handleBlur(e)}
      dateFormat={"yyyy-MM-dd"}
      onChange={(e,a,b) => this.handleChange(e,a,b)}
      field={this.props.field}
      tetherConstraints={[]}
      disabled={this.props.disabled}
      placeholderText="yyyy-mm-dd"
      enableTabLoop={false}
      onKeyDown={(e) => this.doKeyDownEvent(e)}
      monthsShown={this.props.monthsShown ? this.props.monthsShown : 1}
      showPreviousMonths={this.props.showPreviousMonths ? this.props.showPreviousMonths : false}
    />;


    label = this.props.name == undefined ? "" : label;
    if (this.props.hideLabel == true) {
      label = "";
    }

    let className = this.props.className == "undefined" ? "" : this.props.className;

    if (this.state.hasError){
      className += " error";
    }

    if (this.props.readOnly != undefined && this.props.readOnly == true) {
      return <div className="readOnly-field">{moment(input.props.selected).format("yyyy-MM-DD")}</div>;
    }   
    return (
      <div  className={"field " + (className)}> 
        {label}
        {(this.props.invReqField && !selectedValue)? 
        <div className="datepicker_outer" style={this.getStyleForInvoiceDateField(this.props)}>  
          {input}
        </div>: 
        <div className="datepicker_outer">  
          {input}
        </div>} 
      </div>
    );
  }
}

//{border: "1px solid red", borderRadius: "0.3rem"}

//============================================================================= StartDatePicker =============================================================================
export function StartDatePicker(props) {
  const bound = useContext(DataContext);
  const field = props.field;

  return <MyDatePicker field={field} name={props.name} update={() => bound.magicalSet("_lastChange", field)} required={props.required} />
}

//============================================================================= EndDatePicker =============================================================================
export function EndDatePicker(props) {
  const bound = useContext(DataContext);
  const startField = props.startField;
  const termField = props.termField;
  const field = props.field;

  const periodToUpdater = (from) => {
    //if set term and end date unset, then set end date to end of month
    if (bound.magicalGet(termField, null) != null && bound.magicalGet("_lastChange") && bound.magicalGet("_lastChange") != field) {      
      //bound.magicalSet("_lastChange", field);
      let newEnd = Util.addTerm(bound.magicalGet(startField), bound.magicalGet(termField));
      if (!newEnd.isSame(bound.magicalGet(field))) {
        bound.magicalSet(field, newEnd.format("YYYY-MM-DD"));
      }
    }
  }

  useEffect(() => {
    bound.magicalState(termField, () => { periodToUpdater("term") });
    bound.magicalState(startField, () => { periodToUpdater("start") });
  }, [bound]);

  return <MyDatePicker field={field} name={props.name ? props.name : "End"} update={() => bound.magicalSet("_lastChange", field)} required={props.required} />
}

//============================================================================= TermPicker =============================================================================
export function TermPicker(props) {
  const bound = useContext(DataContext);
  const [lookups, lookupsDataset] = useData("lookup", { method: "fetchOptions" });
  const terms = useRef([]);
  const startField = props.startField;
  const endField = props.endField;
  const field = props.field;

  useEffect(() => {
    if (terms.current.length <= 0) {
      const lookupData = lookupsDataset.filter({ name: "term" });
      if (lookupData.length > 0) {
        terms.current = _.sortBy(_.keys(lookupData[0].options), (option) => { return parseInt(option) });
      }
    }
  }, [lookupsDataset.loading])

  const onPeriodChange = (from) => {
    if (bound.magicalGet(startField) != undefined && bound.magicalGet(endField) != undefined && bound.magicalGet(startField) != "" && bound.magicalGet(endField) != "" && bound.magicalGet("_lastChange") && bound.magicalGet("_lastChange") != field) {
      console.log("Term from: " + from + " _lastChange: " + bound.magicalGet("_lastChange"));
      //bound.magicalSet("_lastChange", field);
      //set term to difference between periodFrom and periodTo
      const difference = Math.ceil(Math.abs(moment(bound.magicalGet(startField)).diff(moment(bound.magicalGet(endField)), "months", true)));
      bound.magicalSet(field, difference);
    }    
  }

  useEffect(() => {
    bound.magicalState(startField, (v, e) => { onPeriodChange("start") });
    bound.magicalState(endField, (v, e) => { onPeriodChange("end") });
  }, [bound]);

  return <div className="termpicker" key="term-picker">
    <InputTextWithSelect parent="termField" field={field} name="Contract Term Length" options={terms.current} onChange={() => bound.magicalSet("_lastChange", field)} required={props.required} />
  </div>
}

